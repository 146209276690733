
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import { Modal } from "bootstrap";
import store from "@/store";

export default defineComponent({
  name: "workdayResume",
  emits: ["preloadReload"],
  components: {
    ElectronicSignature,
  },
  setup() {
    let store = useStore();
    return { store };
  },
  data() {
    return {
      donorCount: 0,
      supervisorId: "",
      supervisorList: [],
    };
  },
  mounted() {
    ApiService.query("index/users", {
      params: { permission: ["supervise_user"] },
    }).then((response) => {
      this.supervisorList = response.data;
    });
  },
  methods: {
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    reassignSupervisor() {
      if (this.workDay) {
        Swal.fire({
          title: "Cambio de supervisora",
          text: "Seleccione la nueva supervisora.",
          input: "select",
          inputValue: this.workDay.supervisor_id,
          customClass: {
            input: "form-control form-control-solid",
          },
          confirmButtonText: "Reasignar",
          cancelButtonText: "Cancelar",
          inputOptions: this.supervisorList,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "Debe elegir una supervisora";
            }
          },
          preConfirm: (value) => {
            if (this.workDay) {
              ApiService.put("workdays/" + this.workDay.id, {
                supervisorId: value,
              })
                .then((response) => {
                  this.$emit("preloadReload");
                })
                .catch(this.catchErrors);
            }
          },
        });
      }
      return false;
    },
    treatDayWithSignature(signature) {
      if (this.workDay) {
        ApiService.post("workdays/" + this.workDay.id + "/treat", {
          signature: signature,
        }).then(() => {
          this.$emit("preloadReload");
        });
      }
    },
    closeDayWithSignature(signature) {
      if (this.workDay) {
        ApiService.put("workdays/" + this.workDay.id, {
          status: "closed",
          donorCount: this.donorCount,
          signature: signature,
        }).then(() => {
          this.$emit("preloadReload");
        });
      }
    },
    closeDayWithoutSignature() {
      if (this.workDay) {
        ApiService.put("workdays/" + this.workDay.id, {
          status: "closed",
          donorCount: this.donorCount,
        }).then(() => {
          this.$emit("preloadReload");
        });
      }
    },
    workdayTreatment() {
      var modal = new Modal(document.getElementById("treatSignature"));
      modal.show();
    },
    deletePredonor(id) {
      console.log(id);
      Swal.fire({
        title: "Descartar precarga",
        text: "Atención: Está a punto de descartar los datos de esta precarga. Esta acción no podrá ser revertida.",
        icon: "error",
        showCancelButton: true,

        dangerMode: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",

        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary",
        },
      }).then((resp) => {
        if (resp.isConfirmed === true) {
          ApiService.delete("preloads/" + id).then(() => {
            this.$emit("preloadReload");
          });
        }
      });
    },
    closeDay() {
      Swal.fire({
        title: "Cerrar día de trabajo",
        text: "Atención: Está a punto de cerrar su día de trabajo, el mismo no podrá ser modificado. Confirme la cantidad de donantes registradas.",
        icon: "info",
        input: "number",
        inputValue: this.workDay ? this.workDay.donors.length : 0,
        showCancelButton: true,
        confirmButtonText: "Cerrar día",
        cancelButtonText: "Cancelar",
        preConfirm: (donorCount) => {
          if (this.workDay && donorCount < this.workDay.donors_count) {
            Swal.fire({
              icon: "error",
              text: "La cantidad ingresada no puede ser inferior a la cantidad de precargas registradas. Verifique los datos.",
            });
          }
        },
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-secondary",
        },
      })
        .then((resp) => {
          if (resp.isConfirmed === true) {
            this.donorCount = resp.value;
            if (this.donorCount > 0) {
              var modal = new Modal(document.getElementById("closeSignature"));
              modal.show();
            } else {
              this.closeDayWithoutSignature();
            }
          }
        })
        .catch((error) => {
          Swal.showValidationMessage(error);
        });
    },
  },
  props: {
    validFUM: Array,
    workDay: Object,
    expired: Boolean,
    status: String,
    widgetClasses: String,
  },
});
